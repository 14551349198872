<template>
  <v-footer height="30px" class="wrapper-footer d-flex justify-center align-center">
    <span class="text-footer">
        Copyright © 2021 All rights reserved. Rinen | Privacy Policy
    </span>
  </v-footer>
</template>

<script>
export default {};
</script>

<style scoped>
.text-footer {
  font-size: 10px;
}
.wrapper-footer {
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>