<template>
  <div class="register" id="register">
    <v-app>
      <vue-element-loading
        :active="isLoadSubmit"
        spinner="line-scale"
        color="#EF4035"
      />
      <v-container fluid>
        <v-card class="pt-8 mt-6 card-wrapper-bg">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
              <h1>ลงทะเบียน (Register)</h1>
            </div>
          </v-app-bar>
          <v-form>
            <v-container fluid class="px-5">

                    <div class="wrapper-content-step2 mt-1">
                      <validation-observer
                        ref="observer"
                        v-slot="{ invalid }"
                        v-if="dataRegister.role == 'school'"
                      >
                        <form @submit.prevent="submitRegister">

                          <validation-provider
                            v-slot="{ errors }"
                            name="ชื่อ-นามสกุล"
                            rules="required1"
                          >
                            <v-text-field
                              :error-messages="errors"
                              v-model="dataRegister.full_name"
                              label="ชื่อ-นามสกุล (Full Name)"
                              outlined
                              dense
                              color="#2E2253"
                            ></v-text-field>
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="เบอร์โทรศัพท์"
                            :rules="{
                              required1: true,
                              regex: '^([0-9]+)$',
                            }"
                          >
                            <v-text-field
                              :error-messages="errors"
                              v-model="dataRegister.phone"
                              label="เบอร์โทร (Phone)"
                              outlined
                              dense
                              color="#2E2253"
                            ></v-text-field>
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="ที่อยู่"
                            rules="required1"
                          >
                            <v-textarea
                              :error-messages="errors"
                              v-model="dataRegister.address"
                              label="ที่อยู่ (Address)"
                              outlined
                              dense
                              color="#2E2253"
                              rows="2"
                            ></v-textarea>
                          </validation-provider>
                          <div class="d-flex justify-space-between">
                            <v-btn color="#2E2253" text @click.prevent="previos"
                              >ย้อนกลับ</v-btn
                            >
                            <v-btn
                              :disabled="invalid"
                              @click.prevent="submitRegister"
                              large
                              color="#ef4036"
                              class="white--text"
                              >ลงทะเบียน</v-btn
                            >
                          </div>
                        </form>
                      </validation-observer>

                    </div>

            </v-container>
          </v-form>
          <!-- <Footer /> -->
        </v-card>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import HeaderTitlePage from "@/components/HeaderTitlePage.vue";
import Footer from "@/components/Footer.vue";
import Swal from "sweetalert2";

import { required, digits, regex } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

import RegisterService from "@/services/register.service";

// extend("digits", {
//     ...digits,
//   message: "{_field_} needs to be {length} digits. ({_value_})",
// });

// import VConsole from '@/assets/vconsole.min.js';
// import VConsole from '../../node_modules/vconsole/dist/vconsole.min'
// var vConsole = new VConsole();
// console.log('Hello world');
extend("required1", {
  ...required,
  message: "โปรดระบุ{_field_}ของคุณ",
});
extend("required2", {
  ...required,
  message: "โปรดเลือก{_field_}ของคุณ",
});
extend("regex", {
  ...regex,
  message: "โปรดระบุ{_field_}ของคุณให้ถูกต้อง",
});

import liff from "@line/liff";

export default {
  name: "Register",
  components: {
    HeaderTitlePage,
    Footer,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      e1: 1,
      isLoadSubmit: false,
      isLoadData: false,
      itemsAllRole: [
        { text: "School", value: "school" },
        { text: "Supplier", value: "supplier" },
      ],
      itemsAllSchool: [],
      itemsAllCompany: [],
      itemsPositionSup: ["Admin", "Sales"],
      dataRegister: {
        uid: "",
        role: "school",
        // organization_name: [],
        full_name: "",
        phone: "",
        address: "",
        // position: "",
      },
    };
  },
  async created() {
      await liff.init({ liffId: "1656130154-rElLqEp0" }, () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          this.liffGetProfile();
        }
      },
      err => console.log(err.code, err.message)
    );
  },
  methods: {
    liffGetProfile() {
      liff
        .getProfile()
        .then((profile) => {
          console.log(profile);
          this.dataRegister.uid = profile.userId;
        })
        .catch((err) => console.log(err));
    },
    // selectRole() {
    //   this.e1 = 2;
    //   this.isLoadData = true;
    //   if (this.dataRegister.role === "school") {
    //     RegisterService.getSchools()
    //       .then((res) => {
    //         console.log(res);
    //         this.itemsAllSchool = res.data;
    //         this.isLoadData = false;
    //       })
    //       .catch((err) => {
    //         this.isLoadData = false;
    //         console.log(err);
    //       });
    //   }
    //   if (this.dataRegister.role === "supplier") {
    //     RegisterService.getSuppliers()
    //       .then((res) => {
    //         this.itemsAllCompany = res.data;
    //         this.isLoadData = false;
    //       })
    //       .catch((err) => {
    //         this.isLoadData = false;
    //         console.log(err);
    //       });
    //   }
    // },
    // selectSchool() {
    //   let idSchool = this.dataRegister.organization_name.id;
    //   RegisterService.getSelectSchool(idSchool)
    //     .then((res) => {
    //       //console.log(res.data.schoolAddress);
    //       this.dataRegister.address = res.data.address;
    //       this.isLoadData = false;
    //     })
    //     .catch((err) => {
    //       this.isLoadData = false;
    //       console.log(err);
    //     });
    // },
    // selectCompany() {
    //   console.log(this.dataRegister.organization_name);
    //   //this.dataRegister.address = this.dataRegister.organization_name.address;
    //   let idSupplier = this.dataRegister.organization_name.id;
    //   RegisterService.getSelectSupplier(idSupplier)
    //     .then((res) => {
    //       // console.log(res.data.schoolAddress);
    //       this.dataRegister.address = res.data.supplierAddress;
    //       this.isLoadData = false;
    //     })
    //     .catch((err) => {
    //       this.isLoadData = false;
    //       console.log(err);
    //     });
    // },
    submitRegister() {
      Swal.fire({
        title: "ลงทะเบียน",
        text: "ยืนยันข้อมูลการลงทะเบียนของคุณ",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoadSubmit = true;
          let dataRegisterObj = null;
          // if (this.dataRegister.role === "Dusit") {
          //   dataRegisterObj = {
          //     uid: this.dataRegister.uid,
          //     role: this.dataRegister.role,
          //     full_name: this.dataRegister.full_name,
          //     phone: this.dataRegister.phone,
          //   };
          // }
          // if (this.dataRegister.role === "school") {
          //   dataRegisterObj = {
          //     line_uid: this.dataRegister.uid,
          //     user_type: this.dataRegister.role,
          //     school: {
          //       id: this.dataRegister.organization_name.id.toString(),
          //       name: this.dataRegister.organization_name.name,
          //     },
          //     name: this.dataRegister.full_name,
          //     mobile_no: this.dataRegister.phone,
          //   };
          // }
          // if (this.dataRegister.role === "supplier") {
          //   let dataSupplier = []
          //   this.dataRegister.organization_name.forEach(res => {
          //     let data = {
          //       id: res.id.toString(),
          //       name: res.supplierName
          //     }
          //     dataSupplier.push(data)
          //   })
            dataRegisterObj = {
              name: this.dataRegister.full_name,
              mobile_no: this.dataRegister.phone,
              line_uid: this.dataRegister.uid,
              user_type: this.dataRegister.role,
              school: {
                id: 1,
                name: 'dummy'
              },
            };
          // }
          console.log(dataRegisterObj);
          RegisterService.registerUser(dataRegisterObj)
            .then(async (res) => {
              console.log(res);
              this.isLoadSubmit = false;
              await Swal.fire({
                icon: "success",
                title: "ลงทะเบียนสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              liff.closeWindow();
            })
            .catch(async (err) => {
              this.isLoadSubmit = false;
              await Swal.fire({
                icon: "error",
                title: `${err}`,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    previos() {
      this.e1 = 1;
      this.dataRegister.school_name = "";
      this.dataRegister.company_name = "";
      this.dataRegister.full_name = "";
      this.dataRegister.phone = "";
      this.dataRegister.address = "";
      this.dataRegister.position_sup = "";
    },
  },
};
</script>

<style scoped>
.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}
.v-stepper {
  box-shadow: none !important;
}
.wrapper-content-step1 h1 {
  font-size: 18px;
  color: #292929;
  font-weight: 500;
}
.v-stepper__header {
  display: none;
}
</style>