var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register",attrs:{"id":"register"}},[_c('v-app',[_c('vue-element-loading',{attrs:{"active":_vm.isLoadSubmit,"spinner":"line-scale","color":"#EF4035"}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pt-8 mt-6 card-wrapper-bg"},[_c('v-app-bar',{staticClass:"navbar",attrs:{"app":"","height":"50px"}},[_c('div',{staticClass:"header-title"},[_c('h1',[_vm._v("ลงทะเบียน (Register)")])])]),_c('v-form',[_c('v-container',{staticClass:"px-5",attrs:{"fluid":""}},[_c('div',{staticClass:"wrapper-content-step2 mt-1"},[(_vm.dataRegister.role == 'school')?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitRegister($event)}}},[_c('validation-provider',{attrs:{"name":"ชื่อ-นามสกุล","rules":"required1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"ชื่อ-นามสกุล (Full Name)","outlined":"","dense":"","color":"#2E2253"},model:{value:(_vm.dataRegister.full_name),callback:function ($$v) {_vm.$set(_vm.dataRegister, "full_name", $$v)},expression:"dataRegister.full_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"เบอร์โทรศัพท์","rules":{
                            required1: true,
                            regex: '^([0-9]+)$',
                          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"เบอร์โทร (Phone)","outlined":"","dense":"","color":"#2E2253"},model:{value:(_vm.dataRegister.phone),callback:function ($$v) {_vm.$set(_vm.dataRegister, "phone", $$v)},expression:"dataRegister.phone"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ที่อยู่","rules":"required1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"ที่อยู่ (Address)","outlined":"","dense":"","color":"#2E2253","rows":"2"},model:{value:(_vm.dataRegister.address),callback:function ($$v) {_vm.$set(_vm.dataRegister, "address", $$v)},expression:"dataRegister.address"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"color":"#2E2253","text":""},on:{"click":function($event){$event.preventDefault();return _vm.previos($event)}}},[_vm._v("ย้อนกลับ")]),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":invalid,"large":"","color":"#ef4036"},on:{"click":function($event){$event.preventDefault();return _vm.submitRegister($event)}}},[_vm._v("ลงทะเบียน")])],1)],1)]}}],null,false,314542133)}):_vm._e()],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }