import axios from 'axios'
class RegisterService {
    getSchools() {
        return axios.get('/schools')
                .then(response => {
                    return response
                })
    }

    getSuppliers() {
        return axios.get('/suppliers')
            .then(response => {
                return response
            })
    }

    getSelectSchool(idSchool) {
        return axios.get(`/schools/${idSchool}`)
            .then(response => {
                return response
            })
    }

    getSelectSupplier(idSupplier) {
        return axios.get(`/suppliers/${idSupplier}`)
            .then(response => {
                return response
            })
    }

    registerUser(dataRegisterObj) {
        return axios.post(`/users`, dataRegisterObj, { headers: { 
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*", }
          })
    }
}


export default new RegisterService();